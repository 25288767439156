import axios from 'axios';
import { handleCsrfToken, toCamelCase, toSnakeCase } from '@/src/util/service-mixins.js';

export default class EndUsersService {
  constructor() {
    Object.assign(this, handleCsrfToken);
    this.assignCsrfToken();
  }

  async getEndUsers(params = {}) {
    let orgIdParam, pageParam;

    if (params.organizationId) {
      orgIdParam = `organization_id=${params.organizationId}`;
    }

    if (params.page) {
      pageParam = `page[number]=${params.page.number}&page[size]=${params.page.size}`;
    }

    const queryParams = [orgIdParam, pageParam]
      .filter(Boolean) // Filter out undefined params
      .join('&')
      .trim();

    let requestUrl = '/api/end_users';
    if (queryParams.length > 0) requestUrl += `?${queryParams}`;

    const response = await axios.get(requestUrl);
    return toCamelCase(response.data);
  }

  async createEndUser(endUser) {
    const requestUrl = '/api/end_users';

    try {
      const requestBody = toSnakeCase({ endUser });
      const response = await axios.post(requestUrl, requestBody);
      return toCamelCase(response.data);
    } catch (error) {
      throw toCamelCase(error.response.data);
    }
  }

  async updateEndUser(endUser) {
    const requestUrl = `/api/end_users/${endUser.id}`;
    try {
      const requestBody = toSnakeCase({ endUser });
      const response = await axios.put(requestUrl, requestBody);
      return toCamelCase(response.data);
    } catch (error) {
      throw toCamelCase(error.response.data);
    }
  }

  async deleteEndUser(endUser) {
    const requestUrl = `/api/end_users/${endUser.id}`;
    await axios.delete(requestUrl);
    return true;
  }
}
