import axios from 'axios';
import { handleCsrfToken, toCamelCase, toSnakeCase } from '@/src/util/service-mixins.js';

export default class AccountsService {
  constructor() {
    Object.assign(this, handleCsrfToken);
    this.assignCsrfToken();
  }

  async getAccount(accountId) {
    const requestUrl = `/api/accounts/${accountId}`;
    try {
      const response = await axios.get(requestUrl);
      return toCamelCase(response.data);
    } catch (error) {
      if (error?.response?.data) {
        throw toCamelCase(error.response.data);
      }
      throw error;
    }
  }

  async updateAccount(account) {
    const requestUrl = `/api/accounts/${account.id}`;
    try {
      const requestBody = toSnakeCase({ account });
      const response = await axios.put(requestUrl, requestBody);
      return toCamelCase(response.data);
    } catch (error) {
      if (error?.response?.data) {
        throw toCamelCase(error.response.data);
      }
      throw error;
    }
  }
}
