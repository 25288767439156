import axios from 'axios';
import { handleCsrfToken, toCamelCase, toSnakeCase } from '@/src/util/service-mixins.js';

export default class UploadsService {
  constructor() {
    Object.assign(this, handleCsrfToken);
    this.assignCsrfToken();
  }

  async addAttachment(blob) {
    const requestUrl = '/api/uploads';

    try {
      const response = await axios.post(requestUrl, toSnakeCase({ blob }));

      return toCamelCase(response.data);
    } catch (error) {
      if (error?.response?.data) {
        throw toCamelCase(error.response.data);
      }
      throw error;
    }
  }

  async upload(attachment, file, signal = null) {
    const {
      checksum,
      contentType,
      directUpload: { url, headers },
    } = attachment;

    headers['Content-MD5'] = checksum;
    headers['Content-Type'] = contentType;
    headers['Cache-Control'] = 'no-cache, no-store';
    headers['Expires'] = 0;

    try {
      // signal is an optional object that can be used to cancel the axios request
      await axios.put(url, file, { headers, ...(signal && { signal }) });
      return true;
    } catch (error) {
      // If the request was cancelled via signal, we still throw an error
      // It is up to the consumer to handle this error
      if (error?.response?.data) {
        throw toCamelCase(error.response.data);
      }
      throw error;
    }
  }
}
